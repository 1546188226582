import { Column } from 'material-table';
import React from 'react';
import { UserSummary } from '../../app/ApiGen';
import { TableLink } from '../../components/Link';
import { getCondensedDate } from '../dateFormat';
import { Normalised } from '../request';

export type UsersListData = Pick<Normalised<UserSummary>, 'id' | 'name' | 'email'> & {
  updated: Date;
  created: Date;
};

export function toUsersListData(users: UserSummary[]): UsersListData[] {
  return users.map((user) => ({
    id: user.id,
    email: user.attributes.email,
    name: user.attributes.name,
    created: new Date(user.attributes.created),
    updated: new Date(user.attributes.updated),
  }));
}

export function usersListColumns(args: { url: string }): Column<UsersListData>[] {
  const { url } = args;
  return [
    {
      field: 'email',
      title: 'Email',
      defaultSort: 'asc',
      render: (rowData) => <TableLink to={`${url}/${rowData.id}`}>{rowData.email}</TableLink>,
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'created',
      title: 'Member Since',
      render: ({ updated }) => getCondensedDate(updated),
    },
    {
      field: 'updated',
      title: 'Membership Updated',
      hidden: true,
      render: ({ updated }) => getCondensedDate(updated),
    },
  ];
}
