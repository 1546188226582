import { ChartDataset, ScatterDataPoint } from 'chart.js';
import { BasePalette, basePalette, evnexPalette } from '../../../../../../../design-system';
import { ChargePointLog } from '../../../../../../../utils/api';
import { MeteringMeasurand, MeterValue, MeterValues, SampledValueMessage } from '../types/meteringCharts';

export type DiagnosticsChartType =
  | 'connection'
  | 'current'
  | 'currentOffered'
  | 'power'
  | 'voltage'
  | 'temperature'
  | 'event';

type ChartValues = Pick<SampledValueMessage, 'timestamp' | 'value'>;
type LocationLabel = 'EV' | 'House';
type MessagesByPhase = Record<Phase, ChartValues[]>;
type MeterValueMessages = Record<LocationLabel, MessagesByPhase>;
type Phase = ThreePhase | 'All';
type ThreePhase = 'L1' | 'L2' | 'L3';
type ThreePhaseMessages = Omit<MessagesByPhase, 'All'>;
type Timestamp = string;

export const diagnosticsChartIds: Record<DiagnosticsChartType, string> = {
  connection: 'connectionChart',
  current: 'currentChart',
  currentOffered: 'currentOfferedChart',
  power: 'powerChart',
  voltage: 'voltageChart',
  temperature: 'temperatureChart',
  event: 'eventChart',
};

export const CHART_DATE_FORMAT = 'HH:mm:ss DD/MM/YY';

const metricTitle: Record<MeteringMeasurand, string> = {
  'Current.Import': 'Current (import)',
  'Current.Offered': 'Current (offered)',
  Voltage: 'Voltage',
  'Power.Active.Import': 'Power',
  'Energy.Active.Import.Register': 'Energy',
  Frequency: 'Frequency',
  Temperature: 'Temperature',
};

const BorderColour: Record<LocationLabel, Record<Phase, string>> = {
  EV: {
    All: basePalette.green.main,
    L1: basePalette.purple.main,
    L2: evnexPalette.tussock.main,
    L3: basePalette.red.main,
  },
  House: {
    All: basePalette.purple.main,
    L1: evnexPalette.enabled.main,
    L2: evnexPalette.tussock.main,
    L3: basePalette.red.main,
  },
};

const PhaseSuffix: Record<Phase, string> = {
  L1: ' (ϕ1)',
  L2: ' (ϕ2)',
  L3: ' (ϕ3)',
  All: '',
};

const deduplicateLogs = (meterValueEvents: ChargePointLog[]): ChargePointLog[] => {
  const logs = meterValueEvents.reduce((acc: Record<string, ChargePointLog>, item) => {
    if (item.chargePointEventDate) {
      acc[`${item.chargePointEventDate}-${item.event}`] = item;
    }
    return acc;
  }, {});
  return Object.values(logs);
};

export const getMeterValueEvents = (insights: ChargePointLog[]): ChargePointLog[] => {
  const meterValueEvents: ChargePointLog[] = insights.filter((log) => log.event === 'MeterValues');
  return deduplicateLogs(meterValueEvents);
};

export const getMeterValueMessages = (
  insights: ChargePointLog[],
  sampleMeasurand: MeteringMeasurand,
): SampledValueMessage[] => {
  const meterValueEvents = getMeterValueEvents(insights);

  const meterValues: MeterValue[] = meterValueEvents.flatMap((log) => (log.data as MeterValues).meterValue);

  return meterValues.flatMap((meter) => {
    const { sampledValue: sampledValues, timestamp } = meter;
    const filteredSamples = sampledValues.filter((value) => value.measurand === sampleMeasurand);
    return filteredSamples.map((sampledValue) => ({ ...sampledValue, timestamp }));
  });
};

const getAxisValues = (messages: ChartValues[]): ScatterDataPoint[] =>
  messages.map((data) => ({ x: new Date(data.timestamp).getTime(), y: parseFloat(data.value) }));

export const getTemperatureChartData = (insights: ChargePointLog[], palette: BasePalette): ChartDataset<'line'>[] => {
  const messages: ChartValues[] = getMeterValueMessages(insights, 'Temperature');
  return [
    { label: metricTitle.Temperature, data: getAxisValues(messages), borderColor: palette.green.main, borderWidth: 1 },
  ];
};

function defaultMessagesByPhase(): MessagesByPhase {
  return { All: [], L1: [], L2: [], L3: [] };
}

function splitByLocation(meterValueMessages: SampledValueMessage[]): MeterValueMessages {
  return meterValueMessages.reduce<MeterValueMessages>(
    (acc, meterValueMessage) => {
      const phase = meterValueMessage.phase as Phase | undefined;
      const location = meterValueMessage.location === 'Inlet' ? 'House' : 'EV';
      if (phase !== undefined) {
        acc[location][phase] = [...acc[location][phase], meterValueMessage];
        return acc;
      }
      acc[location].All = [...acc[location].All, meterValueMessage];
      return acc;
    },
    { EV: defaultMessagesByPhase(), House: defaultMessagesByPhase() },
  );
}

function getDataset(args: {
  label: LocationLabel;
  measurand: MeteringMeasurand;
  data: ChartValues[];
  phase: Phase;
  borderColour?: string;
}): ChartDataset<'line'> {
  const { data, label, measurand, phase, borderColour } = args;
  return {
    label: `${label} - ${metricTitle[measurand]}${PhaseSuffix[phase]}`,
    data: getAxisValues(data),
    borderColor: borderColour ?? BorderColour[label][phase],
    borderWidth: 1,
  };
}

function getValuesPerTimestamp(perPhaseData: ThreePhaseMessages): Map<Timestamp, string> {
  const sum: Record<Timestamp, number> = {};
  return Object.keys(perPhaseData).reduce<Map<Timestamp, string>>((acc, k) => {
    const phase = k as ThreePhase;
    perPhaseData[phase].forEach((m) => {
      const { timestamp } = m;
      const value = parseFloat(m.value);
      if (sum[timestamp]) {
        sum[timestamp] += value;
      } else {
        sum[timestamp] = value;
      }
      acc.set(timestamp, sum[timestamp].toString());
    });
    return acc;
  }, new Map());
}

function getSummedChartData(args: {
  label: LocationLabel;
  measurand: MeteringMeasurand;
  perPhaseData: ThreePhaseMessages;
}): ChartDataset<'line'> {
  const { label, measurand, perPhaseData } = args;
  const valuesPerTimestamp = getValuesPerTimestamp(perPhaseData);
  const summedData = Array.from(valuesPerTimestamp).map(([timestamp, value]) => ({ timestamp, value }));
  return getDataset({ label, measurand, data: summedData, phase: 'All' });
}

function getChartDataByPhase(args: {
  perPhaseData: ThreePhaseMessages;
  measurand: MeteringMeasurand;
  label: LocationLabel;
}): ChartDataset<'line'>[] {
  const { label, measurand, perPhaseData } = args;
  return Object.keys(perPhaseData).map((k) => {
    const phase = k as ThreePhase;
    return getDataset({ label, measurand, phase, data: perPhaseData[phase] });
  });
}

function getSinglePhaseChartData(args: {
  allPhaseData: ChartValues[];
  l1Data: ChartValues[];
  measurand: MeteringMeasurand;
  label: LocationLabel;
  borderColour?: string;
}): ChartDataset<'line'> {
  const { allPhaseData, l1Data, label, measurand, borderColour } = args;
  return getDataset({
    label,
    measurand,
    data: [...allPhaseData, ...l1Data],
    phase: 'All',
    borderColour,
  });
}

function getThreePhaseChartData(args: {
  perPhaseData: ThreePhaseMessages;
  measurand: MeteringMeasurand;
  label: LocationLabel;
}): ChartDataset<'line'>[] {
  const { label, measurand, perPhaseData } = args;
  const perPhaseChartData = getChartDataByPhase({ label, measurand, perPhaseData });
  const totalPhaseChartData = getSummedChartData({ label, measurand, perPhaseData });
  return measurand === 'Power.Active.Import' ? [totalPhaseChartData, ...perPhaseChartData] : perPhaseChartData;
}

export const getChartData = (params: {
  insights: ChargePointLog[];
  measurand: MeteringMeasurand;
  borderColour?: string;
}): ChartDataset<'line'>[] => {
  const { insights, measurand, borderColour } = params;
  const meterValueMessages = getMeterValueMessages(insights, measurand);

  if (meterValueMessages.length === 0) {
    return [];
  }

  const messagesByLocation = splitByLocation(meterValueMessages);

  return Object.keys(messagesByLocation).reduce<ChartDataset<'line'>[]>((acc, key) => {
    const label = key as LocationLabel;
    const { All: allPhaseData, ...perPhaseData } = messagesByLocation[label];
    const isThreePhase = perPhaseData.L2.length > 0 && perPhaseData.L3.length > 0;
    if (isThreePhase) {
      return [...acc, ...getThreePhaseChartData({ label, measurand, perPhaseData })];
    }
    if (allPhaseData.length > 0 || perPhaseData.L1.length > 0) {
      return [
        ...acc,
        getSinglePhaseChartData({ label, measurand, allPhaseData, l1Data: perPhaseData.L1, borderColour }),
      ];
    }
    return acc;
  }, []);
};
