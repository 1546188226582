import moment from 'moment-timezone';
import { Session, TransactionStopReason } from '../../app/ApiGen';
import { NormalisedSession } from '../../views/Organisations/Organisation/Reports/MonthlyUseByCharger/types';
import { chargePointHasAttributes, locationHasAttributes } from '../format';
import { getAddressAsString } from '../locations/address';
import { Normalised } from '../request';

type SessionField = Extract<
  NormalisedSession,
  'id' | 'evseId' | 'connectorId' | 'chargePointReferenceId' | 'token' | 'startDate' | 'endDate'
> & {
  chargePointId: string;
  chargePointRefId: string;
  chargePointName: string;
  locationName: string;
  locationAddress: string;
  duration?: number;
  usage?: string;
  electricityCost?: { amount: string; currency: string };
  carbonOffset?: string;
  stopReason?: TransactionStopReason;
};

export interface TableSessionModel extends Record<keyof SessionField, unknown> {
  id: string;
  evseId: string;
  connectorId: string;
  chargePointRefId?: string;
  chargePointName: string;
  locationName?: string;
  locationAddress?: string;
  token?: {
    id?: string;
    uid?: string;
    name?: string;
  };
  startDate: string;
  endDate?: string;
  chargingStarted?: string;
  chargingStopped?: string;
  duration?: number;
  usage?: string;
  electricityCost?: { amount: string; currency: string };
  carbonOffset?: string;
  stopReason?: TransactionStopReason;
  totalCarbonUsage?: number;
  cost?: { amount: string; currency: string };
}

export function toTableSessionModel(data: Normalised<Session>): TableSessionModel {
  const {
    chargePoint,
    chargingConfiguration,
    connectorId,
    cost,
    electricityCost,
    endDate,
    evseId,
    id,
    location,
    startDate,
    token,
    totalCarbonUsage,
    totalCost,
    totalPowerUsage,
    transaction,
  } = data;
  return {
    carbonOffset: totalPowerUsage ? ((totalPowerUsage / 1000) * 0.3).toFixed(2) : undefined,
    chargePointName: chargePointHasAttributes(chargePoint) ? chargePoint.name : '',
    chargePointRefId: chargePointHasAttributes(chargePoint) ? chargePoint.referenceId : '',
    connectorId,
    cost: {
      amount: cost?.total ?? totalCost?.amount ?? '0',
      currency: chargingConfiguration?.currency ?? electricityCost?.currency ?? 'NZD',
    },
    duration: endDate ? moment(endDate).diff(moment(startDate)) : undefined,
    endDate,
    evseId,
    id,
    locationAddress: locationHasAttributes(location) ? getAddressAsString(location.address) : undefined,
    locationName: locationHasAttributes(location) ? location.name : '',
    startDate,
    stopReason: transaction?.reason,
    token: { id: token?.tokenId, name: token?.tokenName },
    totalCarbonUsage,
    usage: totalPowerUsage ? (totalPowerUsage / 1000).toFixed(2) : undefined,
  };
}
