import type { Column, TableColumn } from '../../dataTable/columns';
import { sortByLocale, sortByValue } from '../../sortBy';
import type { TableSessionModel } from '../index';
import { renderCarbonOffset, renderChargingCost, renderStopReason } from './render';
import { searchCarbonOffset, searchChargingCost, searchStopReason } from './search';

export function sessionIdColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'id',
    title: 'Session ID',
    type: 'numeric',
    hidden: true,
  };
}

export function evseIdColumn(): TableColumn<TableSessionModel> {
  return {
    title: 'EVSE ID',
    field: 'evseId',
    hidden: true,
  };
}

export function connectorIdColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'connectorId',
    title: 'Connector ID',
    hidden: true,
  };
}

export function stopReasonColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'reason',
    title: 'Stop reason',
    render: renderStopReason,
    customFilterAndSearch: searchStopReason,
  };
}

export function carbonOffsetColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'carbonOffset',
    title: 'Carbon offset',
    customSort: sortByValue('carbonOffset'),
    render: renderCarbonOffset,
    customFilterAndSearch: searchCarbonOffset,
  };
}

export function costColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'cost.amount',
    title: 'Total cost',
    customSort: sortByValue('cost.amount'),
    render: renderChargingCost,
    customFilterAndSearch: searchChargingCost,
  };
}

export function tokenUidColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'token.uid',
    title: 'Token UID',
    customSort: sortByLocale('token.uid'),
    hidden: true,
  };
}

export function tokenIdColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'token.id',
    title: 'Token ID',
    customSort: sortByLocale('token.id'),
  };
}

export function tokenNameColumn(): TableColumn<TableSessionModel> {
  return {
    field: 'token.name',
    title: 'Token name',
    customSort: sortByLocale('token.name'),
  };
}
