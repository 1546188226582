import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import prettyMs from 'pretty-ms';
import React from 'react';
import { Session } from '../../../../../../../app/ApiGen';
import { ListItem } from '../../../../../../../design-system';
import { getCondensedDate } from '../../../../../../../utils/dateFormat';
import { Normalised } from '../../../../../../../utils/request';

type RecentSessionProps = Pick<
  Normalised<Session>,
  'startDate' | 'endDate' | 'totalPowerUsage' | 'chargingConfiguration'
> & { cost: number; currency: string };

export const RecentSession: React.FC<RecentSessionProps> = ({
  startDate,
  endDate,
  totalPowerUsage,
  cost,
  currency,
}) => (
  <ListItem>
    <Grid container>
      <Grid item xs={2}>
        {getCondensedDate(startDate)}
      </Grid>
      <Grid item xs={2}>
        {startDate && endDate && <>{prettyMs(moment(endDate).diff(moment(startDate)), { secondsDecimalDigits: 0 })}</>}
      </Grid>
      <Grid item xs={2}>
        {totalPowerUsage !== undefined && <>{Number((totalPowerUsage / 1000).toFixed(2)).toLocaleString()} kWh</>}
      </Grid>
      <Grid item xs={2}>
        {!Number.isNaN(cost) && (
          <>
            ${cost.toFixed(2)} {currency}
          </>
        )}
      </Grid>
    </Grid>
  </ListItem>
);
