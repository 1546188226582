import React from 'react';
import { useListChargePointSessions } from '../../../../../app/ApiGen';
import { getToolbarSearch, RefreshButton } from '../../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Section, Workspace } from '../../../../../design-system';
import { normalise } from '../../../../../utils/request';
import { includesSearch } from '../../../../../utils/search';
import { TableSessionModel, toTableSessionModel } from '../../../../../utils/sessions';
import {
  carbonOffsetColumn,
  connectorIdColumn,
  costColumn,
  evseIdColumn,
  sessionIdColumn,
  stopReasonColumn,
  tokenNameColumn,
  tokenUidColumn,
} from '../../../../../utils/sessions/columns/creator';
import {
  dateFormat,
  durationFormatFromStartEnd,
  powerUsageWithUnit,
} from '../../../../../utils/sessions/columns/format';
import { renderDate, renderTotalCarbonUsage } from '../../../../../utils/sessions/columns/render';
import { searchTotalCarbonUsage } from '../../../../../utils/sessions/columns/search';
import { sortByDate, sortByDuration, sortByValue } from '../../../../../utils/sortBy';
import { useChargePoint } from './Provider';

const dateIncludesSearch = (date: string, searchValue: string): boolean =>
  includesSearch(dateFormat(date), searchValue);

const SessionsView: React.FC = () => {
  const { chargePoint } = useChargePoint();

  const chargePointId: string = chargePoint?.id ?? '';

  const { data, error, refetch, loading } = useListChargePointSessions({ id: chargePointId });
  const tableData: TableSessionModel[] = data ? normalise(data).map(toTableSessionModel) : [];

  if (error) {
    return <DataErrorHandler error={error} refetch={refetch} description="Unable to load charge point sessions" />;
  }

  return (
    <Workspace maxWidth="lg">
      <Section title="Charge point sessions" description="View records of sessions at this charge point.">
        <Card>
          <DataTable<TableSessionModel>
            isLoading={loading}
            options={{ columnsButton: true }}
            toolbarProps={{
              ...getToolbarSearch('sessions'),
              actions: (props: ColumnChangerProps) => (
                <>
                  <ColumnChanger
                    columnsButton={props.columnsButton}
                    columns={props.columns}
                    icons={props.icons}
                    onColumnsChanged={props.onColumnsChanged}
                  />
                  <RefreshButton refetch={refetch} />
                </>
              ),
            }}
            columns={[
              sessionIdColumn(),
              {
                field: 'startDate',
                title: 'Start time',
                defaultSort: 'desc',
                customSort: sortByDate('startDate'),
                render: ({ startDate }: TableSessionModel) => renderDate(startDate),
                customFilterAndSearch: (searchValue: string, { startDate }) =>
                  dateIncludesSearch(startDate, searchValue),
              },
              {
                field: 'endDate',
                title: 'End time',
                customSort: sortByDate('endDate'),
                render: ({ endDate }: TableSessionModel) => renderDate(endDate),
                customFilterAndSearch: (searchValue: string, { endDate }) =>
                  !!endDate && dateIncludesSearch(endDate, searchValue),
              },
              evseIdColumn(),
              connectorIdColumn(),
              tokenUidColumn(),
              { ...tokenNameColumn(), hidden: true },
              {
                title: 'Duration',
                customSort: sortByDuration('startDate', 'endDate'),
                render: ({ startDate, endDate }: TableSessionModel) =>
                  startDate && endDate && <>{durationFormatFromStartEnd(startDate, endDate)}</>,
                customFilterAndSearch: (searchValue: string, { startDate, endDate }) =>
                  !!startDate &&
                  !!endDate &&
                  includesSearch(durationFormatFromStartEnd(startDate, endDate), searchValue),
              },
              stopReasonColumn(),
              {
                field: 'totalCarbonUsage',
                title: 'Carbon use',
                customSort: sortByValue('totalCarbonUsage'),
                render: renderTotalCarbonUsage,
                customFilterAndSearch: searchTotalCarbonUsage,
              },
              { ...carbonOffsetColumn(), hidden: true },
              {
                title: 'Energy delivered',
                customSort: sortByValue('usage'),
                render: ({ usage }) => usage !== undefined && <>{powerUsageWithUnit(usage)}</>,
                customFilterAndSearch: (searchValue: string, { usage }) =>
                  usage !== undefined && includesSearch(powerUsageWithUnit(usage), searchValue),
              },
              costColumn(),
            ]}
            data={tableData}
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default SessionsView;
