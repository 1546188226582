import Button from '@material-ui/core/Button';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useListUsers } from '../../../app/ApiGen';
import { getToolbarSearch } from '../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../components/ExportCsv';
import { ColumnChanger, ColumnChangerProps, DataTable, useNavigationView, Workspace } from '../../../design-system';
import { toUsersListData, usersListColumns, UsersListData } from '../../../utils/users/list';
import { mainNav } from '../../navView';

export const InstallersView: React.FC = () => {
  useNavigationView(mainNav);

  const { url } = useRouteMatch<{ slug: string }>();

  const { data, refetch, error, loading } = useListUsers({
    queryParams: { type: 'installer' },
  });

  const tableData = data?.data ? toUsersListData(data.data) : [];

  if (error) {
    return <DataErrorHandler description="Unable to load installers" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth={false}>
      <DataTable<UsersListData>
        columns={usersListColumns({ url: url.replace('installers', 'users') })}
        data={tableData}
        isLoading={loading}
        options={{ columnsButton: true }}
        toolbarProps={{
          ...getToolbarSearch('installers'),
          actions: (props: React.PropsWithChildren<ColumnChangerProps> & ExportProps<UsersListData>) => (
            <>
              <ColumnChanger
                columnsButton={props.columnsButton}
                columns={props.columns}
                icons={props.icons}
                onColumnsChanged={props.onColumnsChanged}
              />
              <ExportCsv<UsersListData>
                columns={props.columns}
                data={props.data}
                exportFileName="installers"
                getFieldValue={props.getFieldValue}
                icons={props.icons}
              />
              <Button component={Link} to="/admin/create/installer" color="primary" variant="contained">
                Create installer account
              </Button>
            </>
          ),
        }}
      />
    </Workspace>
  );
};
