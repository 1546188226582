import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import React from 'react';
import { uid } from 'react-uid';
import { Section, ValueComponentProps } from '../../design-system';
import { downloadFile, FileAttachment, formatFileName, splitFiles } from '../../utils/file';
import { errorSnackbar } from '../ErrorSnackbar';

type Props = {
  file: FileAttachment;
};

const ImageTile: React.FC<Props & { key: string }> = ({ key, file }) => {
  const [hasImageError, toggleHasImageError] = React.useState<boolean>(false);

  const { fileUrl, filename } = file;
  const formattedFileName = formatFileName({ filename });

  return (
    <Section key={key} style={{ width: '60%' }}>
      {hasImageError ? (
        <Alert severity="warning">Error loading {formattedFileName}</Alert>
      ) : (
        <>
          {formattedFileName}
          <a href={fileUrl}>
            <img
              src={fileUrl}
              alt={filename}
              style={{ width: '90%' }}
              onLoad={() => toggleHasImageError(false)}
              onError={() => toggleHasImageError(true)}
            />
          </a>
        </>
      )}
    </Section>
  );
};

export const CertificateOfComplianceDownload: React.FC<Props> = ({ file }) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  return (
    <Section style={{ display: 'flex', flexDirection: 'column' }}>
      Certificate of Compliance PDF
      <Button
        color="primary"
        onClick={() => {
          downloadFile(file).catch((e) => errorSnackbar('Unable to download file', closeSnackbar, enqueueSnackbar, e));
        }}
        style={{ width: '30%' }}
        variant="contained"
      >
        Download
      </Button>
    </Section>
  );
};

export const FileAttachmentsValue: React.FC<ValueComponentProps<FileAttachment[]>> = ({ value: attachments }) => {
  if (attachments.length === 0) {
    return <Typography>None</Typography>;
  }

  const { certificateOfCompliance, images } = splitFiles(attachments);

  return (
    <Section style={{ display: 'flex', flexDirection: 'column' }}>
      {certificateOfCompliance && <CertificateOfComplianceDownload file={certificateOfCompliance} />}
      <Section style={{ display: 'flex', flexFlow: 'row wrap' }}>
        {images.map((file, idx) => (
          <ImageTile key={uid(idx)} file={file} />
        ))}
      </Section>
    </Section>
  );
};
