import { includesSearch } from '../../search';
import {
  CARBON_UNIT,
  carbonOffsetFormat,
  electricityCostFormat,
  stopReasonMapper,
  chargingCostCostFormat,
} from './format';
import { TableSessionModel } from '..';

export function searchStopReason(searchValue: string, { stopReason }: TableSessionModel): boolean {
  return includesSearch(stopReasonMapper(stopReason), searchValue);
}

export function searchCarbonOffset(searchValue: string, { carbonOffset }: TableSessionModel): boolean {
  return !!carbonOffset && includesSearch(`${carbonOffsetFormat(carbonOffset)} ${CARBON_UNIT}`, searchValue);
}

export function searchElectricityCost(searchValue: string, { electricityCost }: TableSessionModel): boolean {
  return includesSearch(electricityCostFormat(electricityCost), searchValue);
}

export function searchTotalCarbonUsage(searchValue: string, { totalCarbonUsage }: TableSessionModel): boolean {
  return (
    totalCarbonUsage !== undefined &&
    includesSearch(`${totalCarbonUsage.toLocaleString(undefined, { maximumFractionDigits: 0 })} g CO2`, searchValue)
  );
}

export function searchChargingCost(searchValue: string, { cost }: TableSessionModel): boolean {
  return includesSearch(chargingCostCostFormat(cost), searchValue);
}
