import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import { ChargePointSummary, useListChargePointSessions } from '../../../../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../../../../components/ErrorHandler';
import { Card, List, ListHeader, Loading } from '../../../../../../../design-system';
import type { SubState } from '../../../../../../../store/types';
import { normalise, Normalised } from '../../../../../../../utils/request';
import { RecentSession } from './RecentSession';

export const RecentSessions: React.FC<{
  chargePoint: Normalised<ChargePointSummary>;
  organisation: SubState<'organisation'>;
}> = ({ chargePoint, organisation }) => {
  const { loading, error, data, refetch } = useListChargePointSessions({ id: chargePoint.id });

  if (loading) {
    return (
      <Card style={{ height: '100%' }} title="Recent sessions">
        <Loading p={2} size={32} />
      </Card>
    );
  }

  if (error || !data) {
    return (
      <DataErrorHandler description="Unable to load recent sessions" error={error} refetch={refetch} type="embedded" />
    );
  }

  const sessions = data ? normalise(data).slice(0, 5) : [];

  return (
    <Card
      style={{ height: '100%' }}
      title="Recent sessions"
      footer={
        <>
          <Box ml="auto" />
          <Button
            component={Link}
            to={`/organisations/${organisation.slug}/charge-points/${chargePoint.id}/sessions`}
            color="primary"
            variant="text"
            size="small"
            endIcon={<ChevronRightIcon />}
          >
            View sessions
          </Button>
        </>
      }
    >
      <Box flex="1 1 auto">
        <List alternate="odd">
          <ListHeader>
            <Grid container>
              <Grid item xs={2}>
                Start time
              </Grid>
              <Grid item xs={2}>
                Duration
              </Grid>
              <Grid item xs={2}>
                Energy delivered
              </Grid>
              <Grid item xs={2}>
                Total cost
              </Grid>
            </Grid>
          </ListHeader>

          {sessions &&
            sessions.map((session, idx) => (
              <RecentSession
                key={uid(session, idx)}
                startDate={session.startDate}
                endDate={session.endDate}
                totalPowerUsage={session.totalPowerUsage}
                cost={Number(session.cost?.total)}
                currency={session.chargingConfiguration?.currency ?? 'NZD'}
              />
            ))}

          {sessions.length === 0 && (
            <Box p={2} display="flex" justifyContent="center">
              <Typography variant="body2">No sessions to display</Typography>
            </Box>
          )}
        </List>
      </Box>
    </Card>
  );
};
