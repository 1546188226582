export type FileAttachment = {
  filename: string;
  fileUrl: string;
};

type FilesByType = {
  images: FileAttachment[];
  certificateOfCompliance: FileAttachment | undefined;
};

type FileExtension = typeof pdfExtension | string;

const pdfExtension = 'pdf';

const imageExtensions = ['jpg', 'jpeg', 'png'];

const upperCaseRegex = /[A-Z\s]+/g;

export const getNameAndExtension = (fileName: string): string[] => fileName.split('.');

export const getExtension = (fileName: string): FileExtension => {
  const extension = getNameAndExtension(fileName).at(1);
  if (!extension) {
    throw new Error(`Could not find extension in file name ${fileName}`);
  }
  return extension;
};

function splitByCapital(name: string): string {
  const firstLetter = name.slice(0, 1);
  const restOfName = name.slice(1);
  const upperCaseFirstLetter = firstLetter.toUpperCase();
  const lowerCasedRestOfName = restOfName.replaceAll(upperCaseRegex, (original) => {
    const trimmed = original.trim();
    return trimmed ? ` ${trimmed.toLowerCase()}` : original;
  });
  return `${upperCaseFirstLetter}${lowerCasedRestOfName}`;
}

export function formatFileName({ filename }: Pick<FileAttachment, 'filename'>): string {
  const [name] = getNameAndExtension(filename);
  if (!name) {
    return '(Unknown file name)';
  }
  return splitByCapital(name);
}

const getFileBlob = async ({ fileUrl }: Pick<FileAttachment, 'fileUrl'>): Promise<Blob> => {
  const response = await fetch(fileUrl, { method: 'GET', headers: { 'Content-Type': 'application/pdf' } });
  return response.blob();
};

export async function downloadFile({ filename, fileUrl }: FileAttachment): Promise<void> {
  const link = document.createElement('a');
  const blob = await getFileBlob({ fileUrl });
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export function splitFiles(attachments: FileAttachment[]): FilesByType {
  return attachments.reduce<FilesByType>(
    (acc, attachment) => {
      const extension = getExtension(attachment.filename);
      if (extension === 'pdf') {
        return { ...acc, certificateOfCompliance: attachment };
      }
      if (imageExtensions.includes(extension)) {
        return { ...acc, images: [...acc.images, attachment] };
      }
      return acc;
    },
    { images: [], certificateOfCompliance: undefined },
  );
}
